import React, { useContext } from "react";
import { Button as BButton } from "antd";
import SearchContext from "../../Context/Search/SearchContext";
import RolePlay from "./RolePlay";
import Kernal from "./Kernal";
import Titan from "./Titan";
import Automation from "./Automation";
import FeedTable from "./FeedTable";
import ScreenShoot from "./ScreenShoot";
import VectorData from "./VectorData";
import Vision from "./Vision";
import Organization from "./Organization";
import Instructions from "./Instructions";
import Views from "./Views";
import Playtest from "./Playtest";
import Documents from "./Documents";
import FeedPending from "./FeedPending";

export default function Index() {
  const { global, setGlobal } = useContext(SearchContext);

  return (
    <div>
      <div className="roleplay-main1">
        <BButton
          type={global.tab === "feed" ? "primary" : "default"}
          onClick={() => {
            setGlobal({ ...global, tab: "feed" });
          }}
        >
          Feed
        </BButton>
        
        <BButton
          type={global.tab === "documents" ? "primary" : "default"}
          onClick={() => {
            setGlobal({ ...global, tab: "documents" });
          }}
        >
          Documents
        </BButton>
        <BButton
          type={global.tab === "organization" ? "primary" : "default"}
          onClick={() => {
            setGlobal({ ...global, tab: "organization" });
          }}
        >
          Organization
        </BButton>
        <BButton
          type={global.tab === "views" ? "primary" : "default"}
          onClick={() => {
            setGlobal({ ...global, tab: "views" });
          }}
        >
          Views
        </BButton>{" "}
     
        <BButton
          type={global.tab === "automation" ? "primary" : "default"}
          onClick={() => {
            setGlobal({ ...global, tab: "automation" });
          }}
          
        >
          Automation
        </BButton> 
      </div>
 
      {global.tab === "feed" ? <FeedTable /> : null}
     
      {global.tab === "organization" ? <Organization /> : null}
      {global.tab === "documents" ? <Documents /> : null}

      {global.tab === "automation" ? <Automation /> : null}
    </div>
  );
}
